import React from "react"
import styled from "styled-components"
import BigButton from "../../bits/bigButton"
import Carousel from "@brainhubeu/react-carousel"
import "./newCarousel.css"
import Graphic from "../../static/images/infographic.png"
import Bulletproof from "../../static/images/Bulletproof.png"
import BoardOversight from "../../static/images/BoardOversight.png"


const Item = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: start; */
  padding: var(--padding-m);
  margin-bottom: 16px;
  @media (max-width: 768px) {
    padding: 0px;
    margin-left:0px;
  }
`

const ItemTitle = styled.h3`
  margin: 0;
`

const ItemText = styled.p`
  max-width: 36ch;
`

const Image = styled.img`
  height: 400px;
`

const ListedItem = ({ title, description, url, image }) => (
  <Item>
    <Image src={image} alt={title} />
    <ItemTitle>{title}</ItemTitle>
    <ItemText>{description}</ItemText>
    <BigButton href={url} text="View" />
  </Item>
)

const WorkCarousel = data => {
  return (
    <Carousel
      autoPlay={6000}
      animationSpeed={300}
      infinite
      addArrowClickHandler
      breakpoints={{
        768: {
          slidesPerPage: 1,
          arrows: true,
        },
        1024: {
          slidesPerPage: 2,
          arrows: true,
        },
        2440: {
          slidesPerPage: 3,
          arrows: true,
        },
      }}
      slidesPerPage={3}
    >
      <ListedItem
        title="Covid Challenges"
        description="Remote working compliance challenges"
        url="https://www.linkedin.com/feed/update/urn:li:activity:6696705271441481728"
        image={Graphic}
      />
      <ListedItem
        title="Bulletproof Compliance"
        description="GECKOs ManCo Solution"
        url="https://www.linkedin.com/feed/update/urn:li:activity:6694561545336221696"
        image={Bulletproof}
      />
      <ListedItem
        title="Board Oversight"
        description="Financial Reporting Solution"
        url="https://www.linkedin.com/feed/update/urn:li:activity:6694561545336221696"
        image={BoardOversight}
      />
    </Carousel>
  )
}

export default WorkCarousel
