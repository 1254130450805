import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import SiteLink from "../../bits/siteLink"
import useWorkPost from "../../hooks/workListHook"
import Fade from "react-reveal/Fade"

const Card = styled.div`
  background-color: ${props => (props.color ? `${props.color}` : "black")};
  /*background: rgb(6,11,31);
  background: -moz-linear-gradient(180deg, rgba(6,11,31,1) 6%, rgba(0,108,255,1) 96%);
  background: -webkit-linear-gradient(180deg, rgba(6,11,31,1) 6%, rgba(0,108,255,1) 96%);
  background: linear-gradient(180deg, rgba(6,11,31,1) 6%, rgba(0,108,255,1) 96%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060b1f",endColorstr="#006cff",GradientType=1); */
  padding: var(--padding-m) var(--padding-m) 0 var(--padding-m);
  margin-bottom: var(--padding-m);
  transition: var(--transition);
  //border-radius: 8px;

  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0 25px 40px 0 rgba(0, 0, 0, 0.08);
  }
`

const Subtitle = styled.h6`
  color: var(--color-white-500);
  font-weight: 400;
  letter-spacing: 1.5px;
  margin: 0;
`

const Title = styled.h3`
  margin-top: 16px;
  color: var(--color-white-500);
`

const Image = styled(Img)`
  background-color: rgba(0, 0, 0, 0);
`

const WorkIndex = () => {
  const postList = useWorkPost()

  return (
    <div>
      {postList.map(post => (
        <Fade bottom>
          <SiteLink to={`/usecases${post.path}`} className="noeffect">
            <Card key={post.path} color={post.color}>
              <Subtitle>{post.title}</Subtitle>
              <Title>{post.subtitle}</Title>
              <Image
                fluid={post.cover.childImageSharp.fluid}
                alt={post.title}
              />
            </Card>
          </SiteLink>
        </Fade>
      ))}
    </div>
  )
}
export default WorkIndex
