import React from "react"
import SiteLink from "../bits/siteLink"
import styled from "styled-components"
import PropTypes from "prop-types"

const Wrapper = styled.div`
  border: 1px var(--color-brand-strong) solid;
  padding: 0.5rem var(--var-padding-s);
  text-transform: uppercase;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  //border-radius: 5px;

  h6 {
    color: var(--color-brand-strong);
    font-weight: var(--font-weight-regular);
    margin-bottom: 0;
    margin-right: 8px;
  }

  span {
    font-size: 14px;
  }

  &:hover {
    background: var(--color-brand-strong);

    & > * {
      color: var(--color-white-500);
    }
  }
`

const BigButton = ({ href, text }) => {
  return (
    <SiteLink to={href} target="_blank" className="noeffect" rel="noopener">
      <Wrapper>
        <h6>{text}</h6>
      </Wrapper>
    </SiteLink>
  )
}

export default BigButton

BigButton.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
}

BigButton.defaultProps = {
  text: "Show More",
}
