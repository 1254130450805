import { graphql, useStaticQuery } from "gatsby"

const useWorkPost = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        limit: 2
        sort: { fields: frontmatter___date }
        filter: {
          fileAbsolutePath: { regex: "/usecases/" }
          frontmatter: { draft: { ne: true } }
        }
      ) {
        nodes {
          id
          excerpt(pruneLength: 200)
          timeToRead
          frontmatter {
            date
            tags
            title
            subtitle
            path
            color
            cover {
              publicURL
              childImageSharp {
                fluid(maxHeight: 1200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allMdx.nodes.map(postEdge => ({
    tags: postEdge.frontmatter.tags,
    cover: postEdge.frontmatter.cover,
    title: postEdge.frontmatter.title,
    excerpt: postEdge.frontmatter.tldr,
    timeToRead: postEdge.timeToRead,
    color: postEdge.frontmatter.color,
    subtitle: postEdge.frontmatter.subtitle,
    path: postEdge.frontmatter.path,
    // path: postEdge.fields.slug,
    //date: postEdge.node.fields.date,
  }))
}

export default useWorkPost
