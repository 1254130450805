import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"
import PageTitle from "../bits/pageTitle"
import WorkList from "../components/WorkList/workList"
import styled from "styled-components"
import Boxed from "../bits/box"
import WorkCarousel from "../components/Carousel/workCarousel"

const WorkBoxed = styled(Boxed)``

const Row = styled.section`
  padding: var(--var-padding-m) 0;

  &:first-child {
    padding-bottom: 0;
  }
`

const ClearRow = styled(Row)`
  padding: 0;
`

const UseCases = () => {
  return (
    <Layout>
      <SEO title="Use Cases" />
      <WorkBoxed>
        <PageTitle title="Use Cases" subtitle="GECKO Case Studies and modules" />
        <Row>
          <h2>What we offer</h2>
          {/* Icons used here on the each pages below are provided by FlatIcon.com */}
          <WorkList />
        </Row>
        <ClearRow>
          <h2>Infographics </h2>
          <p>Check out our latest projects</p>
        </ClearRow>
      </WorkBoxed>
      <WorkCarousel />
    </Layout>
  )
}

export default UseCases
